import React from 'react';
import Hero from '../components/Hero';
import Projects from '../components/Projects';
import Team from '../components/Team';
import Contact from '../components/Contact';

export default function HomePage() {
  return (
    <main>
      <Hero />
      <Projects />
      <Team />
      <Contact />
    </main>
  );
}